
<template>
    <div class="employeeAccess-container">
        <div class="filter-panel" >
            <CSSelect  style="width: 200px">
                <el-date-picker
                        v-model="startDate"
                        type="datetime"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        placeholder="开始时间"
                        prefix-icon="el-icon-time"
                        :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <span style="margin-left: -10px;margin-right: 10px;">-</span>
            <CSSelect style="width: 200px" >
                <el-date-picker
                        v-model="endDate"
                        type="datetime"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        placeholder="结束时间"
                        prefix-icon="el-icon-time"
                        :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <input
                    type="text"
                    class="cs-input"
                    style="width: 220px"
                    placeholder="搜索姓名/手机号"
                    v-model="search"
            />
            <button
                    class="btn btn-primary"
                    style="margin-left: 10px;padding-left: 10px;padding-right: 10px"
                    @click="searchData()"
            >
                查询
            </button>
        </div>
        <div class="result-panel">
            <CSTable>
                <template v-slot:header>

                </template>
                <template v-slot:thead>
                    <tr>
                        <th>企业后台登录时间</th>
                        <th>登录人姓名</th>
                        <th>性别</th>
                        <th>手机号</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="item in recordList" :key="item.idCard">
                        <td>{{item.createTime}}</td>
                        <td>{{item.name}}</td>
                        <td>{{ genderObj[item.gender]}}</td>
                        <td>{{item.phone}}</td>
                    </tr>
                </template>
            </CSTable>
            <Pagination/>
        </div>
    </div>
</template>

<script>
    import CSSelect from "@/components/common/CSSelect";
    import {queryCompanyLoginLogUrl}from '@/requestUrl';
    import Pagination from "@/components/Pagination";
    import CSTable from "@/components/common/CSTable";
    export  default {
        name:"loginRecord",
        data(){
            return{
                search:'',
                startDate: dayjs().subtract(1, 'month').format("YYYY-MM-DD") + ' 00:00',
                endDate: dayjs().format("YYYY-MM-DD") + ' 23:59',
                recordList:[],
                genderObj : {
                    1:'男',
                    2:'女',
                    0:'未知'
                }
            }
        },
        components:{
            CSSelect,
            Pagination,
            CSTable
        },
        created() {
            this.searchData();
            window.addEventListener('keydown',this.searchDataEnd);
        },
        beforeDestroy() {
            window.removeEventListener('keydown',this.searchDataEnd)
        },
        deactivated() {
            window.removeEventListener('keydown',this.searchDataEnd)
        },
        mounted() {
            this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
                this.searchData(pageNo);
            });
        },
        methods:{
            //回车查询列表
            searchDataEnd(e){
                if(e.keyCode == 13){
                    this.searchData();
                }
            },
            searchData(pageNo = 1,pageSize = 10){
                this.$fly.post(queryCompanyLoginLogUrl,{
                    "userId":this.$vc.getCurrentStaffInfo().id,    //当前登录用户ID
                    "startTime":this.startDate,  //起始时间
                    "endTime":this.endDate,   //结束时间
                    "search":this.search,     //搜索框内容
                    pageSize,   //页面大小
                    pageNo     //页码
                }).then(res => {
                    if(res.code != 0){
                        return
                    }
                    if (pageNo == 1) {
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            total: res.data.total,
                            currentPage: pageNo,
                            pageSize: pageSize
                        });
                    }
                    this.recordList = res.data.datas;
                })
            },

        }
    }
</script>

<style lang="stylus" scoped>
    .select-pan{
        width:150px
    }
</style>